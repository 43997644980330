.StyleGuide_style-guide-section-wrapper {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.StyleGuide_bg-main {
  background-color: var(--color_bg);
}
.StyleGuide_bg-main .sgs-component {
  background-color: var(--color_bg);
}
.StyleGuide_bg-main .StyleGuide_style-guide-section {
  background-color: var(--color_bg);
}

.StyleGuide_bg-main-lite {
  background-color: var(--color_bg_lite);
}
.StyleGuide_bg-main-lite .sgs-component {
  background-color: var(--color_bg_lite);
}
.StyleGuide_bg-main-lite .StyleGuide_style-guide-section {
  background-color: var(--color_bg_lite);
}

.StyleGuide_bg-main-dark {
  background-color: var(--color_bg_dark);
}
.StyleGuide_bg-main-dark .sgs-component {
  background-color: var(--color_bg_dark);
}
.StyleGuide_bg-main-dark .StyleGuide_style-guide-section {
  background-color: var(--color_bg_dark);
}

.StyleGuide_bg-secondary {
  background-color: var(--color_bg_secondary);
}
.StyleGuide_bg-secondary .sgs-component {
  background-color: var(--color_bg_secondary);
}
.StyleGuide_bg-secondary .StyleGuide_style-guide-section {
  background-color: var(--color_bg_secondary);
}

.StyleGuide_bg-secondary-lite {
  background-color: var(--color_bg_secondary_lite);
}
.StyleGuide_bg-secondary-lite .sgs-component {
  background-color: var(--color_bg_secondary_lite);
}
.StyleGuide_bg-secondary-lite .StyleGuide_style-guide-section {
  background-color: var(--color_bg_secondary_lite);
}

.StyleGuide_bg-secondary-dark {
  background-color: var(--color_bg_secondary_dark);
}
.StyleGuide_bg-secondary-dark .sgs-component {
  background-color: var(--color_bg_secondary_dark);
}
.StyleGuide_bg-secondary-dark .StyleGuide_style-guide-section {
  background-color: var(--color_bg_secondary_dark);
}

.StyleGuide_bg-tertiary {
  background-color: var(--color_bg_tertiary);
}
.StyleGuide_bg-tertiary .sgs-component {
  background-color: var(--color_bg_tertiary);
}
.StyleGuide_bg-tertiary .StyleGuide_style-guide-section {
  background-color: var(--color_bg_tertiary);
}

.StyleGuide_bg-tertiary-lite {
  background-color: var(--color_bg_tertiary_lite);
}
.StyleGuide_bg-tertiary-lite .sgs-component {
  background-color: var(--color_bg_tertiary_lite);
}
.StyleGuide_bg-tertiary-lite .StyleGuide_style-guide-section {
  background-color: var(--color_bg_tertiary_lite);
}

.StyleGuide_bg-tertiary-dark {
  background-color: var(--color_bg_tertiary_dark);
}
.StyleGuide_bg-tertiary-dark .sgs-component {
  background-color: var(--color_bg_tertiary_dark);
}
.StyleGuide_bg-tertiary-dark .StyleGuide_style-guide-section {
  background-color: var(--color_bg_tertiary_dark);
}