.LoaderIconAnimation_root {
  border: 8px solid var(--color_primary);
  border-top: 8px solid var(--color_grey_800);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  animation: LoaderIconAnimation_spin 2s linear infinite;
}
@keyframes LoaderIconAnimation_spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}