.TableRow_root {
  color: var(--color_bg_tertiary_contrast_text);
  display: table-row;
  vertical-align: middle;
  outline: 0;
  background-color: var(--color_bg_tertiary);
}

.TableRow_selected {
  background-color: var(--color_bg_tertiary_lite);
}