@import '/home/runner/work/pos/pos/src/styles/sass/_globals.scss';

html {
  // global changes to all rem values based on screen size
  font-size: 6px;
  // global var "$colors-dark-map" from from "_colors-dark.scss" file
  // global var "$colors-lite-map" from from "_colors-lite.scss" file
  //  - default to dark mode color set
  //  - "dark mode" is representative to our user facing color set
  //  - "lite mode" is representative to our customer facing color set
  @include spread-map-to-css-vars($colors-dark-map);
  color: color-var(color_text_primary);
  background-color: color-var(color_bg);
}

@include breakpoints-t3-up('sm') {
  html {
    font-size: 7px;
  }
}
@include breakpoints-t3-up('md') {
  html {
    font-size: 8px;
  }
}
@include breakpoints-t3-up('lg') {
  html {
    font-size: 9px;
  }
}
@include breakpoints-t3-up('xl') {
  html {
    font-size: 10px;
  }
}

