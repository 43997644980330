.TabButtons_btns {
  display: inline-grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  background-color: var(--color_grey_800);
  min-width: max-content;
  position: relative;
  border-radius: 3rem;
}
.TabButtons_btns.TabButtons_variantUnderline {
  display: grid;
  border-radius: 0;
  background-color: transparent;
}

.TabButtons_btn {
  color: var(--color_text_primary);
  background-color: transparent;
  border-radius: 5px;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  gap: 1rem;
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
  min-width: 13rem;
  padding: 12px 16px;
  border: 0;
}
.TabButtons_btn.TabButtons_variantUnderline {
  padding: 1.2rem 1.6rem;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Medium */
  font-weight: 500;
}
.TabButtons_btn.TabButtons_selected {
  color: var(--color_primary_contrast_text);
}
.TabButtons_btn.TabButtons_selected.TabButtons_variantUnderline {
  color: var(--color_primary);
}

.TabButtons_indicator {
  position: absolute;
  background-color: var(--color_primary);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: 0;
  border-radius: 3rem;
}
.TabButtons_indicator.TabButtons_variantUnderline {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 3px solid var(--color_primary);
}