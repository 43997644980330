.HotKeyCheckItemButtons_contentGroup.HotKeyCheckItemButtons_contentGroupSelected .HotKeyCheckItemButtons_contentGroupTitle {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_primary);
}
.HotKeyCheckItemButtons_contentGroup.HotKeyCheckItemButtons_contentGroupSelected .HotKeyCheckItemButtons_contentGroupBtn {
  border-left-color: var(--color_primary);
}

.HotKeyCheckItemButtons_contentGroupTitle {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  /* Caption/Semibold */
  font-size: 1.2rem; /* 12px */
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
  color: var(--color_bg_secondary_contrast_text);
  background-color: var(--color_bg_secondary_lite);
  position: sticky;
  top: 0;
  z-index: 1;
}

.HotKeyCheckItemButtons_contentGroupBtn {
  border-left: 2px solid transparent;
}