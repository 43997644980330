.CheckPanelDiscounts_root {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.CheckPanelDiscounts_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  background-color: var(--color_bg_tertiary);
}

.CheckPanelDiscounts_icon {
  margin-left: -0.25rem;
  margin-right: 0.75rem;
}

.CheckPanelDiscounts_grid {
  width: 100%;
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 7rem;
}