.FormFieldTextArea_root {
  -webkit-tap-highlight-color: transparent;
  background: none;
  border: 0;
  box-sizing: content-box;
  color: var(--color_text_primary);
  display: block;
  font-family: inherit;
  padding: 0;
  margin: 0;
  min-width: 0;
  width: 100%;
  user-select: none;
}
.FormFieldTextArea_root:focus-visible {
  outline: none;
}
.FormFieldTextArea_root.FormFieldTextArea_size_sm {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldTextArea_root.FormFieldTextArea_size_md {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.FormFieldTextArea_root.FormFieldTextArea_disabled {
  opacity: 0.5;
}
.FormFieldTextArea_root.FormFieldTextArea_variant_underline_box {
  padding: 1rem;
  width: calc(100% - 2rem);
  color: var(--color_text_primary);
  border-bottom: 1px solid var(--color_divider);
  background-color: var(--color_bg_secondary);
}
.FormFieldTextArea_root.FormFieldTextArea_variant_underline_box.FormFieldTextArea_error {
  border-bottom: 1px solid var(--color_error);
}
.FormFieldTextArea_root.FormFieldTextArea_variant_underline {
  padding: 0;
  width: calc(100% - 2rem);
  color: var(--color_text_primary);
  border-bottom: 1px solid var(--color_divider);
  background-color: transparent;
}
.FormFieldTextArea_root.FormFieldTextArea_variant_underline.FormFieldTextArea_error {
  border-bottom: 1px solid var(--color_error);
}
.FormFieldTextArea_root.FormFieldTextArea_variant_no_background {
  padding: 1rem;
  width: calc(100% - 2rem);
  color: var(--color_text_primary);
  background-color: transparent;
}