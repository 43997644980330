.FormFieldSwitch_content {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  position: relative;
}

.FormFieldSwitch_labelStart {
  flex-direction: row-reverse;
}

.FormFieldSwitch_size_sm .FormFieldSwitch_input {
  width: 4rem;
  height: 2rem;
}

.FormFieldSwitch_size_md .FormFieldSwitch_input {
  width: 5rem;
  height: 3rem;
}

.FormFieldSwitch_input {
  opacity: 0 !important;
}

.FormFieldSwitch_inputIcon {
  position: absolute;
  user-select: none;
  pointer-events: none;
}