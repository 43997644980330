.Page_root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: var(--color_text_primary);
  background-color: var(--color_bg);
}
.Page_root.Page_has_training_mode > .Page_content {
  height: calc(100% - 3.5rem);
}
.Page_root.Page_has_panel > .Page_content {
  grid-template-columns: minmax(30rem, 30%) 1fr;
}

.Page_html_mode_dark {
  --color_primary: #E79D3C;
  --color_primary_lite: rgb(236.5890410959, 179.8219178082, 105.4109589041);
  --color_primary_dark: rgb(213.698630137, 132.602739726, 26.301369863);
  --color_primary_contrast_text: #303134;
  --color_secondary: #FFFFFF;
  --color_secondary_lite: white;
  --color_secondary_dark: rgb(229.5, 229.5, 229.5);
  --color_secondary_contrast_text: #303134;
  --color_info: #2095FA;
  --color_info_lite: rgb(81.8815789474, 172.7105263158, 251.1184210526);
  --color_info_dark: rgb(5.0657894737, 123.6052631579, 225.9342105263);
  --color_info_contrast_text: #FFFFFF;
  --color_success: #22BB33;
  --color_success_lite: rgb(53.6153846154, 218.3846153846, 71.9230769231);
  --color_success_dark: rgb(26.1538461538, 143.8461538462, 39.2307692308);
  --color_success_contrast_text: #FFFFFF;
  --color_error: #EA271B;
  --color_error_lite: rgb(238.3012048193, 83.2409638554, 73.6987951807);
  --color_error_dark: rgb(192.2891566265, 27.8313253012, 17.7108433735);
  --color_error_contrast_text: #FFFFFF;
  --color_warn: #E79D3C;
  --color_warn_lite: rgb(236.5890410959, 179.8219178082, 105.4109589041);
  --color_warn_dark: rgb(213.698630137, 132.602739726, 26.301369863);
  --color_warn_contrast_text: #303134;
  --color_bg: #0C141B;
  --color_bg_lite: rgb(27.6923076923, 46.1538461538, 62.3076923077);
  --color_bg_dark: black;
  --color_bg_contrast_text: #FFFFFF;
  --color_bg_secondary: #303134;
  --color_bg_secondary_lite: rgb(72.48, 73.99, 78.52);
  --color_bg_secondary_dark: rgb(23.52, 24.01, 25.48);
  --color_bg_secondary_contrast_text: #FFFFFF;
  --color_bg_tertiary: #202124;
  --color_bg_tertiary_lite: rgb(56, 57.75, 63);
  --color_bg_tertiary_dark: rgb(8, 8.25, 9);
  --color_bg_tertiary_contrast_text: #FFFFFF;
  --color_transparent_lite_25: rgba(255, 255, 255, 0.25);
  --color_transparent_lite_33: rgba(255, 255, 255, 0.33);
  --color_transparent_lite_50: rgba(255, 255, 255, 0.5);
  --color_transparent_dark_50: rgba(12, 20, 27, 0.5);
  --color_text_primary: #FFFFFF;
  --color_text_secondary: #9E9E9E;
  --color_text_tertiary: #757575;
  --color_text_disabled: #646466;
  --color_grey_100: #D3D3D3;
  --color_grey_200: #A7A7A8;
  --color_grey_300: #7A7A7C;
  --color_grey_400: #646466;
  --color_grey_500: #4D4E50;
  --color_grey_600: #424345;
  --color_grey_700: #363436;
  --color_grey_800: #303134;
  --color_grey_900: #202124;
  --color_divider: #BDBDBD;
  --color_divider_lite: rgb(214.5, 214.5, 214.5);
  --color_divider_dark: rgb(163.5, 163.5, 163.5);
  --color_menu_fave: #FFDB15;
}

.Page_html_mode_lite {
  --color_primary: #E79D3C;
  --color_primary_lite: rgb(236.5890410959, 179.8219178082, 105.4109589041);
  --color_primary_dark: rgb(213.698630137, 132.602739726, 26.301369863);
  --color_primary_contrast_text: #303134;
  --color_secondary: #FFFFFF;
  --color_secondary_lite: white;
  --color_secondary_dark: rgb(229.5, 229.5, 229.5);
  --color_secondary_contrast_text: #303134;
  --color_info: #2095FA;
  --color_info_lite: rgb(81.8815789474, 172.7105263158, 251.1184210526);
  --color_info_dark: rgb(5.0657894737, 123.6052631579, 225.9342105263);
  --color_info_contrast_text: #FFFFFF;
  --color_success: #22BB33;
  --color_success_lite: rgb(53.6153846154, 218.3846153846, 71.9230769231);
  --color_success_dark: rgb(26.1538461538, 143.8461538462, 39.2307692308);
  --color_success_contrast_text: #FFFFFF;
  --color_error: #EA271B;
  --color_error_lite: rgb(238.3012048193, 83.2409638554, 73.6987951807);
  --color_error_dark: rgb(192.2891566265, 27.8313253012, 17.7108433735);
  --color_error_contrast_text: #FFFFFF;
  --color_warn: #E79D3C;
  --color_warn_lite: rgb(236.5890410959, 179.8219178082, 105.4109589041);
  --color_warn_dark: rgb(213.698630137, 132.602739726, 26.301369863);
  --color_warn_contrast_text: #303134;
  --color_bg: #FFFFFF;
  --color_bg_lite: #FFFFFF;
  --color_bg_dark: rgb(229.5, 229.5, 229.5);
  --color_bg_contrast_text: #303134;
  --color_bg_secondary: #F6F6F6;
  --color_bg_secondary_lite: white;
  --color_bg_secondary_dark: rgb(220.5, 220.5, 220.5);
  --color_bg_secondary_contrast_text: #303134;
  --color_bg_tertiary: #303134;
  --color_bg_tertiary_lite: rgb(72.48, 73.99, 78.52);
  --color_bg_tertiary_dark: rgb(23.52, 24.01, 25.48);
  --color_bg_tertiary_contrast_text: #FFFFFF;
  --color_transparent_lite_25: rgba(255, 255, 255, 0.25);
  --color_transparent_lite_33: rgba(255, 255, 255, 0.33);
  --color_transparent_lite_50: rgba(255, 255, 255, 0.5);
  --color_transparent_dark_50: rgba(255, 255, 255, 0.5);
  --color_text_primary: #303134;
  --color_text_secondary: #9E9E9E;
  --color_text_tertiary: #757575;
  --color_text_disabled: #646466;
  --color_grey_100: #D3D3D3;
  --color_grey_200: #A7A7A8;
  --color_grey_300: #7A7A7C;
  --color_grey_400: #646466;
  --color_grey_500: #4D4E50;
  --color_grey_600: #424345;
  --color_grey_700: #363436;
  --color_grey_800: #303134;
  --color_grey_900: #202124;
  --color_divider: #BDBDBD;
  --color_divider_lite: rgb(214.5, 214.5, 214.5);
  --color_divider_dark: rgb(163.5, 163.5, 163.5);
  --color_menu_fave: #FFDB15;
}

.Page_content {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
}

.Page_training_mode {
  width: 100%;
  height: 3.5rem;
  background-color: var(--color_success);
  padding: 1rem 0;
  text-align: center;
}

.Page_panel {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--color_bg_secondary);
  overflow: hidden;
}

.Page_main {
  position: relative;
  overflow: hidden;
}