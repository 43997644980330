.Modal_portal .Modal_root:not(:last-of-type) .BackDrop_root {
  visibility: hidden;
}

.Modal_root {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  z-index: 1300;
}
.Modal_root.Modal_open {
  opacity: 1;
}

.Modal_variant_fullScreen .Modal_paper {
  height: 100%;
  width: 100%;
}
.Modal_variant_fullScreen .ModalHeader_root {
  padding: 2rem;
}
.Modal_variant_fullScreen .ModalContent_root {
  height: 100%;
  width: 100%;
}

.Modal_variant_fit .Modal_paper {
  height: 90%;
  width: 65%;
}

.Modal_variant_grow .Modal_paper {
  max-height: 90%;
  width: 65%;
}
.Modal_variant_grow .ModalContent_root {
  height: unset;
  max-height: 100%;
}

.Modal_variant_prompt .Modal_paper {
  max-height: 90%;
  width: 65%;
}
.Modal_variant_prompt .ModalContent_root {
  height: unset;
  max-height: 100%;
}
.Modal_variant_prompt .ModalHeader_root {
  padding: 4rem 4.8rem 4rem 8.8rem;
  margin-bottom: 2rem;
}

.Modal_paper {
  display: flex;
  flex-direction: column;
  background-color: var(--color_bg_secondary);
}

.Modal_variant_fit .Modal_paper {
  height: 90%;
  width: 65%;
}

.Modal_variant_grow .Modal_paper {
  max-height: 90%;
  width: 65%;
}
.Modal_variant_grow .ModalContent_root {
  height: unset;
  max-height: 100%;
}

.Modal_variant_fullScreen .Modal_paper {
  height: 100%;
  width: 100%;
}
.Modal_variant_fullScreen .ModalHeader_root {
  padding: 2rem;
}
.Modal_variant_fullScreen .ModalContent_root {
  height: 100%;
  width: 100%;
}

.Modal_variant_prompt .Modal_paper {
  max-height: 90%;
  width: 65%;
}
.Modal_variant_prompt .ModalContent_root {
  height: unset;
  max-height: 100%;
}
.Modal_variant_prompt .ModalHeader_root {
  padding: 4rem 4.8rem 4rem 8.8rem;
  margin-bottom: 2rem;
}

.Modal_variant_color_warning .ModalHeader_root {
  color: var(--color_warn_contrast_text);
  background-color: var(--color_warn);
}

.Modal_variant_color_error .ModalHeader_root {
  color: var(--color_error_contrast_text);
  background-color: var(--color_error);
}

.Modal_paper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.Modal_paper.Modal_paper_variant_color_main {
  background-color: var(--color_bg);
}
.Modal_paper.Modal_paper_variant_color_secondary {
  background-color: var(--color_bg_secondary);
}

.Modal_loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  opacity: 0;
  z-index: 1;
}
.Modal_loader.Modal_loading {
  opacity: 1;
}
.Modal_loader.Modal_not_loading {
  pointer-events: none;
  user-select: none;
}