.CheckItemDetailSeatPosition_root {
  padding: 2rem;
}

.CheckItemDetailSeatPosition_headingRoot {
  display: flex;
  gap: 0.25rem;
  padding: 1rem;
  height: 6.8rem;
  align-items: center;
}

.CheckItemDetailSeatPosition_headingText {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
}

.CheckItemDetailSeatPosition_optionsContainer {
  overflow: auto;
  display: flex;
  gap: 1rem;
}

.CheckItemDetailSeatPosition_btn {
  position: relative;
  min-height: 6rem;
  gap: 0.25rem;
  border-radius: 0.5rem;
  padding: 1rem;
  color: var(--color_text_primary);
  border: 1px solid transparent;
  background-color: var(--color_bg_tertiary_lite);
  position: relative;
}
@keyframes CheckItemDetailSeatPosition_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.CheckItemDetailSeatPosition_btn::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.CheckItemDetailSeatPosition_btn:active::before {
  animation: CheckItemDetailSeatPosition_pulse 0.75s;
}

.CheckItemDetailSeatPosition_selected {
  border: 1px solid var(--color_bg);
  background-color: var(--color_bg_tertiary);
}

.CheckItemDetailSeatPosition_label {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
}

.CheckItemDetailSeatPosition_tableBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 20rem;
}

.CheckItemDetailSeatPosition_seatBtn {
  min-width: 12rem;
  display: grid;
  grid-template-columns: 1fr 2.5rem;
  align-items: center;
}

.CheckItemDetailSeatPosition_seatBtnLabel {
  grid-column: 1/3;
  grid-row: 1;
}

.CheckItemDetailSeatPosition_seatBtnIcon {
  grid-column: 2;
  grid-row: 1;
}