.BadgeCount_root {
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: var(--color_error);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  min-width: 2.2rem;
  min-height: 2.2rem;
  padding: 0.3rem;
  opacity: 1;
}
.BadgeCount_root.BadgeCount_hide {
  opacity: 0;
}