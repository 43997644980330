.CTA_root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0 2rem;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  min-height: 4rem;
  min-width: 16.5rem;
  border-radius: 0;
  text-transform: capitalize;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 2rem; /* 20px */
  /* Body/Regular */
  font-weight: 400;
}
.CTA_root svg {
  user-select: none;
  pointer-events: none;
}

.CTA_variant_default {
  color: var(--color_text_primary);
  background-color: var(--color_bg_secondary);
  border: 1px solid transparent;
}
.CTA_variant_default.CTA_color_inherit {
  color: inherit;
}
.CTA_variant_default.CTA_color_primary {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_primary);
}
.CTA_variant_default.CTA_color_secondary {
  color: var(--color_secondary_contrast_text);
  background-color: var(--color_secondary);
}
.CTA_variant_default.CTA_color_text_primary {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_text_primary);
}
.CTA_variant_default.CTA_color_text_secondary {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_text_secondary);
}
.CTA_variant_default.CTA_color_error {
  color: var(--color_error_contrast_text);
  background-color: var(--color_error);
}
.CTA_variant_default.CTA_color_info {
  color: var(--color_info_contrast_text);
  background-color: var(--color_info);
}
.CTA_variant_default.CTA_selected {
  background-color: var(--color_bg_secondary_lite);
}
.CTA_variant_default.CTA_selected.CTA_color_primary {
  background-color: var(--color_primary_dark);
}
.CTA_variant_default.CTA_selected.CTA_color_secondary {
  background-color: var(--color_secondary_dark);
}
.CTA_variant_default.CTA_selected.CTA_color_error {
  background-color: var(--color_error_dark);
}
.CTA_variant_default.CTA_selected.CTA_color_info {
  background-color: var(--color_info_dark);
}

.CTA_variant_text {
  color: var(--color_text_primary);
  background-color: transparent;
  border: 1px solid transparent;
}
.CTA_variant_text.CTA_color_inherit {
  color: inherit;
}
.CTA_variant_text.CTA_color_primary {
  color: var(--color_primary);
}
.CTA_variant_text.CTA_color_secondary {
  color: var(--color_secondary);
}
.CTA_variant_text.CTA_color_text_primary {
  color: var(--color_text_primary);
}
.CTA_variant_text.CTA_color_text_secondary {
  color: var(--color_text_secondary);
}
.CTA_variant_text.CTA_color_error {
  color: var(--color_error);
}
.CTA_variant_text.CTA_color_info {
  color: var(--color_info);
}
.CTA_variant_text.CTA_selected {
  background-color: var(--color_bg_secondary_lite);
}
.CTA_variant_text.CTA_selected.CTA_color_error {
  color: var(--color_error);
}
.CTA_variant_text.CTA_selected.CTA_color_info {
  color: var(--color_info);
}

.CTA_variant_text_inline {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  min-width: 0;
  min-height: 0;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  vertical-align: baseline;
}
.CTA_variant_text_inline.CTA_color_inherit {
  color: inherit;
}
.CTA_variant_text_inline.CTA_color_primary {
  color: var(--color_primary);
}
.CTA_variant_text_inline.CTA_color_secondary {
  color: var(--color_secondary);
}
.CTA_variant_text_inline.CTA_color_text_primary {
  color: var(--color_text_primary);
}
.CTA_variant_text_inline.CTA_color_text_secondary {
  color: var(--color_text_secondary);
}
.CTA_variant_text_inline.CTA_color_error {
  color: var(--color_error);
}
.CTA_variant_text_inline.CTA_color_info {
  color: var(--color_info);
}
.CTA_variant_text_inline.CTA_selected.CTA_color_error {
  color: var(--color_error);
}
.CTA_variant_text_inline.CTA_selected.CTA_color_info {
  color: var(--color_info);
}

.CTA_variant_outlined {
  border: 1px solid var(--color_divider);
  background-color: transparent;
  color: var(--color_text_primary);
}
.CTA_variant_outlined.CTA_color_inherit {
  color: inherit;
  border: 1px solid inherit;
}
.CTA_variant_outlined.CTA_color_primary {
  border: 1px solid var(--color_primary);
  color: var(--color_primary);
}
.CTA_variant_outlined.CTA_color_secondary {
  border: 1px solid var(--color_secondary);
  color: var(--color_secondary);
}
.CTA_variant_outlined.CTA_color_text_primary {
  border: 1px solid var(--color_text_primary);
  color: var(--color_text_primary);
}
.CTA_variant_outlined.CTA_color_text_secondary {
  border: 1px solid var(--color_text_secondary);
  color: var(--color_text_secondary);
}
.CTA_variant_outlined.CTA_color_error {
  border: 1px solid var(--color_error);
  color: var(--color_error);
}
.CTA_variant_outlined.CTA_color_info {
  border: 1px solid var(--color_info);
  color: var(--color_info);
}
.CTA_variant_outlined.CTA_selected {
  border: 1px solid var(--color_text_primary);
  background-color: transparent;
}
.CTA_variant_outlined.CTA_selected.CTA_color_inherit {
  border: 1px solid var(--color_text_primary);
  color: var(--color_text_primary);
}
.CTA_size_xs {
  height: 4rem;
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 1.6rem; /* 16px */
  /* Body/SemiBold */
  font-weight: 700;
  gap: 0.25rem;
}
.CTA_size_xs .Copy_variant_button, .CTA_size_xs .CTA_label {
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 1.6rem; /* 16px */
  /* Body/SemiBold */
  font-weight: 700;
}
.CTA_size_xs svg {
  transform: scale(1.2);
}

.CTA_size_sm {
  height: 4.8rem;
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 1.6rem; /* 16px */
  /* Body/SemiBold */
  font-weight: 700;
  gap: 0.25rem;
}
.CTA_size_sm .Copy_variant_button, .CTA_size_sm .CTA_label {
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 1.6rem; /* 16px */
  /* Body/SemiBold */
  font-weight: 700;
}
.CTA_size_sm svg {
  transform: scale(1.7);
}

.CTA_size_lg {
  height: 6.8rem;
  gap: 1.2rem;
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 2.4rem; /* 24px */
  /* Body/SemiBold */
  font-weight: 700;
}
.CTA_size_lg .Copy_variant_button, .CTA_size_lg .CTA_label {
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 2.4rem; /* 24px */
  /* Body/SemiBold */
  font-weight: 700;
}
.CTA_size_lg svg {
  transform: scale(2);
}

.CTA_min_width_self {
  min-width: unset;
}

.CTA_min-width-default {
  min-width: 10rem;
}

.CTA_min_width_sm {
  min-width: 10rem;
}

.CTA_min_width_md {
  min-width: 20rem;
}

.CTA_min_width_lg {
  min-width: 30rem;
}

.CTA_full_width {
  min-width: unset;
  max-width: unset;
  width: 100%;
}

.CTA_full_height {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}

.CTA_rounded {
  border-radius: 0.5rem;
}

.CTA_pill {
  border-radius: 5rem;
}

.CTA_square {
  border-radius: 0;
}

.CTA_bold {
  font-weight: 600;
}

.CTA_can_not_click {
  pointer-events: none;
}

.CTA_can_click {
  position: relative;
}
@keyframes CTA_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.CTA_can_click::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.CTA_can_click:active::before {
  animation: CTA_pulse 0.75s;
}

.CTA_color_disable {
  opacity: 0.5;
}

.CTA_label {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-style: inherit;
}

.CTA_single_line .CTA_label {
  white-space: nowrap;
}

.CTA_single_line_ellipsis .CTA_label {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.CTA_clamp_line .CTA_label {
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  -webkit-box-orient: vertical;
}

.CTA_bold {
  font-weight: 600;
}

.CTA_adorn {
  position: absolute;
}

.CTA_adorn_top_right {
  top: 0.5rem;
  right: 0.5rem;
}

.CTA_padding_xxs {
  padding: 0.25rem;
}

.CTA_padding_xs {
  padding: 0.5rem;
}

.CTA_padding_sm {
  padding: 1rem;
}

.CTA_padding_md {
  padding: 1.5rem;
}

.CTA_padding_lg {
  padding: 2.4rem;
}

.CTA_padding_xl {
  padding: 3.2rem;
}

.CTA_padding_xxl {
  padding: 4rem;
}