.CheckDetailPrintCheckBox_root .CheckDetailPrintCheckBox_icon {
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: transparent;
  border: 1px solid var(--color_grey_300);
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.CheckDetailPrintCheckBox_root.CheckDetailPrintCheckBox_checked .CheckDetailPrintCheckBox_icon {
  border: 2px solid var(--color_grey_600);
  background-color: var(--color_success);
}