.ModifierButton_root {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 1rem;
  text-align: left;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: var(--color_text_primary);
  border-bottom: 1px solid var(--color_transparent_dark_50);
  position: relative;
}
@keyframes ModifierButton_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.ModifierButton_root::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.ModifierButton_root:active::before {
  animation: ModifierButton_pulse 0.75s;
}
.ModifierButton_root .ModifierButton_name {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/Medium */
  font-weight: 500;
}
.ModifierButton_root .ModifierButton_selection_label {
  align-self: flex-start;
  color: var(--color_text_secondary);
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.ModifierButton_root .ModifierButton_selections {
  min-height: 10rem;
}
.ModifierButton_root.ModifierButton_required .ModifierButton_name::after {
  content: " *";
  display: inline;
  color: var(--color_text_primary);
}
.ModifierButton_root.ModifierButton_invalid.ModifierButton_required .ModifierButton_name::after {
  content: " *";
  display: inline;
  color: var(--color_error);
}
.ModifierButton_root.ModifierButton_selected {
  background-color: var(--color_transparent_dark_50);
}