.ReceiptPrintTool_root {
  padding: 1rem;
  display: grid;
  grid-template-columns: 50%;
}

.ReceiptPrintTool_input {
  color: var(--color_bg_secondary);
}

.ReceiptPrintTool_cta {
  background-color: var(--color_success);
}