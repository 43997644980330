.SvgIcon_root {
  font-size: 2rem;
  color: inherit;
}
.SvgIcon_root.SvgIcon_color_inherit {
  color: inherit;
}
.SvgIcon_root.SvgIcon_color_disable {
  color: var(--color_text_disabled);
}
.SvgIcon_root.SvgIcon_color_primary {
  color: var(--color_primary);
}
.SvgIcon_root.SvgIcon_color_secondary {
  color: var(--color_secondary);
}
.SvgIcon_root.SvgIcon_color_error {
  color: var(--color_error);
}
.SvgIcon_root.SvgIcon_color_info {
  color: var(--color_info);
}
.SvgIcon_root.SvgIcon_color_success {
  color: var(--color_success);
}
.SvgIcon_root.SvgIcon_color_warning {
  color: var(--color_warn);
}
.SvgIcon_root.SvgIcon_size_inherit {
  font-size: inherit;
}
.SvgIcon_root.SvgIcon_size_xs {
  font-size: 1rem;
}
.SvgIcon_root.SvgIcon_size_sm {
  font-size: 1.5rem;
}
.SvgIcon_root.SvgIcon_size_md {
  font-size: 2rem;
}
.SvgIcon_root.SvgIcon_size_lg {
  font-size: 2.4rem;
}
.SvgIcon_root.SvgIcon_size_xl {
  font-size: 3rem;
}