.OverlayContainer_root {
  position: fixed;
}

.OverlayContainer_container > * {
  box-shadow: 0 0 3px var(--color_bg);
}

.OverlayContainer_position_bottom_right {
  right: 2rem;
  bottom: 2rem;
}