.EODFailureToastNotification_root {
  background-color: var(--color_bg_tertiary);
  border-radius: 1rem;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 1rem;
}

.EODFailureToastNotification_message {
  word-break: break-word;
  flex-grow: 1;
}