.IconCTA_root {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  margin: 0;
  padding: 0;
  user-select: none;
  vertical-align: middle;
  -webkit-appearance: none;
  text-decoration: none;
  text-transform: capitalize;
  background-color: transparent;
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.IconCTA_root .IconCTA_icon {
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border-width: 3px;
  border-color: transparent;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.8rem;
  height: 6.8rem;
}
.IconCTA_root > * {
  user-select: none;
  pointer-events: none;
}
.IconCTA_root svg {
  user-select: none;
  pointer-events: none;
}

.IconCTA_adorn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  user-select: none;
}

.IconCTA_adorn_top_right {
  align-items: flex-start;
  justify-content: end;
}

.IconCTA_variant_default {
  color: var(--color_text_primary);
}
.IconCTA_variant_default .IconCTA_icon {
  color: var(--color_text_primary);
  background-color: var(--color_bg_secondary);
}
.IconCTA_variant_default .IconCTA_icon svg {
  fill: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_color_inherit {
  color: inherit;
}
.IconCTA_variant_default.IconCTA_color_inherit .IconCTA_icon {
  color: inherit;
}
.IconCTA_variant_default.IconCTA_color_primary {
  color: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_primary .IconCTA_icon {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_primary);
}
.IconCTA_variant_default.IconCTA_color_primary .IconCTA_icon svg {
  fill: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_tertiary {
  color: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_color_tertiary .IconCTA_icon {
  color: var(--color_text_primary);
  background-color: var(--color_bg_tertiary);
}
.IconCTA_variant_default.IconCTA_color_tertiary .IconCTA_icon svg {
  fill: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_color_secondary {
  color: var(--color_secondary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_secondary .IconCTA_icon {
  color: var(--color_secondary_contrast_text);
  background-color: var(--color_secondary);
}
.IconCTA_variant_default.IconCTA_color_secondary .IconCTA_icon svg {
  fill: var(--color_secondary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_primary {
  color: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_primary .IconCTA_icon {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_color_text_primary .IconCTA_icon svg {
  fill: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_secondary {
  color: var(--color_secondary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_secondary .IconCTA_icon {
  color: var(--color_secondary_contrast_text);
  background-color: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_color_text_secondary .IconCTA_icon svg {
  fill: var(--color_secondary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_tertiary {
  color: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_text_tertiary .IconCTA_icon {
  color: var(--color_primary_contrast_text);
  background-color: var(--color_text_tertiary);
}
.IconCTA_variant_default.IconCTA_color_text_tertiary .IconCTA_icon svg {
  fill: var(--color_primary_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_error {
  color: var(--color_error_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_error .IconCTA_icon {
  color: var(--color_error_contrast_text);
  background-color: var(--color_error);
}
.IconCTA_variant_default.IconCTA_color_error .IconCTA_icon svg {
  fill: var(--color_error_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_info {
  color: var(--color_info_contrast_text);
}
.IconCTA_variant_default.IconCTA_color_info .IconCTA_icon {
  color: var(--color_info_contrast_text);
  background-color: var(--color_info);
}
.IconCTA_variant_default.IconCTA_color_info .IconCTA_icon svg {
  fill: var(--color_info_contrast_text);
}
.IconCTA_variant_default.IconCTA_selected .IconCTA_icon {
  background-color: var(--color_bg_secondary_lite);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_primary .IconCTA_icon {
  background-color: var(--color_primary_dark);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_secondary .IconCTA_icon {
  background-color: var(--color_secondary_dark);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_tertiary .IconCTA_icon {
  background-color: var(--color_bg_tertiary_lite);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_text_primary .IconCTA_icon {
  background-color: var(--color_text_primary);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_text_secondary .IconCTA_icon {
  background-color: var(--color_text_secondary);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_text_tertiary .IconCTA_icon {
  background-color: var(--color_text_tertiary);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_error .IconCTA_icon {
  background-color: var(--color_error_dark);
}
.IconCTA_variant_default.IconCTA_selected.IconCTA_color_info .IconCTA_icon {
  background-color: var(--color_info_dark);
}

.IconCTA_variant_outlined {
  color: var(--color_text_primary);
}
.IconCTA_variant_outlined .IconCTA_icon {
  border-width: 3px;
  border-color: var(--color_divider);
  background-color: transparent;
  color: var(--color_text_primary);
}
.IconCTA_variant_outlined.IconCTA_color_inherit {
  color: inherit;
}
.IconCTA_variant_outlined.IconCTA_color_inherit .IconCTA_icon {
  color: inherit;
}
.IconCTA_variant_outlined.IconCTA_color_primary .IconCTA_icon {
  color: var(--color_text_primary);
}
.IconCTA_variant_outlined.IconCTA_color_secondary .IconCTA_icon {
  color: var(--color_text_secondary);
}
.IconCTA_variant_outlined.IconCTA_color_tertiary .IconCTA_icon {
  color: var(--color_bg_tertiary);
}
.IconCTA_variant_outlined.IconCTA_color_text_primary .IconCTA_icon {
  color: var(--color_text_primary);
}
.IconCTA_variant_outlined.IconCTA_color_text_secondary .IconCTA_icon {
  color: var(--color_text_secondary);
}
.IconCTA_variant_outlined.IconCTA_color_text_secondary .IconCTA_icon {
  color: var(--color_text_tertiary);
}
.IconCTA_variant_outlined.IconCTA_color_error .IconCTA_icon {
  color: var(--color_error);
}
.IconCTA_variant_outlined.IconCTA_color_info .IconCTA_icon {
  color: var(--color_info);
}
.IconCTA_variant_outlined.IconCTA_selected .IconCTA_icon {
  background-color: var(--color_bg_tertiary);
  border-color: var(--color_primary);
}
.IconCTA_variant_no_background {
  color: var(--color_text_primary);
}
.IconCTA_variant_no_background .IconCTA_icon {
  background-color: transparent;
  color: var(--color_text_primary);
}
.IconCTA_variant_no_background.IconCTA_color_inherit {
  color: inherit;
}
.IconCTA_variant_no_background.IconCTA_color_inherit .IconCTA_icon {
  color: inherit;
}
.IconCTA_variant_no_background.IconCTA_color_primary .IconCTA_icon {
  color: var(--color_primary);
}
.IconCTA_variant_no_background.IconCTA_color_secondary .IconCTA_icon {
  color: var(--color_secondary);
}
.IconCTA_variant_no_background.IconCTA_color_tertiary .IconCTA_icon {
  color: var(--color_bg_tertiary);
}
.IconCTA_variant_no_background.IconCTA_color_text_primary .IconCTA_icon {
  color: var(--color_text_primary);
}
.IconCTA_variant_no_background.IconCTA_color_text_secondary .IconCTA_icon {
  color: var(--color_text_secondary);
}
.IconCTA_variant_no_background.IconCTA_color_text_tertiary .IconCTA_icon {
  color: var(--color_text_tertiary);
}
.IconCTA_variant_no_background.IconCTA_color_error .IconCTA_icon {
  color: var(--color_error);
}
.IconCTA_variant_no_background.IconCTA_color_info .IconCTA_icon {
  color: var(--color_info);
}
.IconCTA_variant_no_background.IconCTA_selected .IconCTA_icon {
  background-color: var(--color_bg_secondary);
}
.IconCTA_can_not_click {
  pointer-events: none;
}

.IconCTA_can_click .IconCTA_icon {
  position: relative;
}
@keyframes IconCTA_pulse {
  0% {
    opacity: 0.1;
  }
  70% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
}
.IconCTA_can_click .IconCTA_icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
  border-radius: inherit;
  background-color: white;
  user-select: none;
  pointer-events: none;
}
.IconCTA_can_click .IconCTA_icon:active::before {
  animation: IconCTA_pulse 0.75s;
}

.IconCTA_disable_look {
  opacity: 0.5;
}

.IconCTA_square .IconCTA_icon {
  border-radius: 0.5rem;
}

.IconCTA_size_xs {
  gap: 0.25rem;
}
.IconCTA_size_xs .IconCTA_adorn {
  padding: 1.4rem;
}
.IconCTA_size_sm {
  gap: 0.25rem;
}
.IconCTA_size_sm .IconCTA_adorn {
  padding: 1.2rem;
}
.IconCTA_size_lg {
  gap: 1.2rem;
}
.IconCTA_size_lg .IconCTA_adorn {
  padding: 1rem;
}
.IconCTA_size_xl {
  gap: 1.2rem;
}
.IconCTA_size_xl .IconCTA_adorn {
  padding: 0;
}
.IconCTA_size_xl .IconCTA_icon {
  width: 12rem;
  height: 12rem;
}
.IconCTA_padding_xxs {
  padding: 0.25rem;
}

.IconCTA_padding_xs {
  padding: 0.5rem;
}

.IconCTA_padding_sm {
  padding: 1rem;
}

.IconCTA_padding_md {
  padding: 1.5rem;
}

.IconCTA_padding_lg {
  padding: 2.4rem;
}

.IconCTA_padding_xl {
  padding: 3.2rem;
}

.IconCTA_padding_xxl {
  padding: 4rem;
}