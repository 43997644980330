.ProvisionerLanding_pageContentReset {
  padding: 0;
}

.ProvisionerLanding_pageContent {
  height: 100vh;
  width: 100vw;
  align-Items: center;
  justify-Content: center;
  user-Select: none;
  display: flex;
  flex-direction: row;
}

.ProvisionerLanding_splash {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-Right: 0.1rem solid var(--color_divider);
}
@media (max-width: 1200px) {
  .ProvisionerLanding_splash {
    display: none;
  }
}

.ProvisionerLanding_splashItem {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
}

.ProvisionerLanding_actionContainer {
  padding-top: 2rem;
}

.ProvisionerLanding_logo {
  width: 50%;
  height: auto;
  fill: var(--color_bg_contrast_text);
}