.DataTable_root {
  overflow: auto;
}
.DataTable_root table {
  background-color: transparent;
  border-spacing: 0 0.5rem;
}
.DataTable_root table thead th, .DataTable_root table thead td {
  padding-bottom: 0.5rem;
  background-color: transparent;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
  color: var(--color_text_secondary);
}
.DataTable_root table thead tr {
  background-color: transparent;
}
.DataTable_root table thead tr td:first-of-type {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.DataTable_root table thead tr td:last-of-type {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.DataTable_root table tbody td {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Medium */
  font-weight: 500;
}

.DataTable_tableContainer {
  background-color: transparent;
  background-image: unset;
}

.DataTable_tableContainerStickyHeader {
  overflow-x: visible;
}

.DataTable_sticky {
  position: sticky;
  box-shadow: 0.5rem 0.5rem 0.5rem var(--color_bg);
  border-left: 1px solid var(--color_divider);
  z-index: 1;
}

.DataTable_stickyHeadCell {
  position: sticky;
  top: 0;
  z-index: 2;
}

.DataTable_titleSpacing {
  margin: auto 1rem auto 0;
}

.DataTable_header {
  display: flex;
  padding: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.DataTable_table {
  table-layout: fixed;
  overflow-x: auto;
}

.DataTable_noDataFooter {
  display: flex;
}