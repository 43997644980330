.SGIconCta_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  width: 100%;
}

.SGIconCta_cell {
  max-height: 10rem;
}

.SGIconCta_adorn-icon {
  color: var(--color_info);
}

.SGIconCta_form-select {
  min-width: 10rem;
}