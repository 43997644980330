.FontIcon_root {
  font-family: "Material Symbols Rounded";
  font-weight: normal;
  font-style: normal;
  font-size: 2.4rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.FontIcon_variant_regular {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 40;
}

.FontIcon_variant_filled {
  font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 40;
}

.FontIcon_color_inherit {
  color: inherit;
}

.FontIcon_color_primary {
  color: var(--color_primary);
}

.FontIcon_color_secondary {
  color: var(--color_secondary);
}

.FontIcon_color_success {
  color: var(--color_success);
}

.FontIcon_color_info {
  color: var(--color_info);
}

.FontIcon_color_error {
  color: var(--color_error);
}

.FontIcon_color_warn {
  color: var(--color_warn);
}

.FontIcon_size_inherit {
  font-size: inherit;
}

.FontIcon_size_xs {
  font-size: 1.6rem;
}

.FontIcon_size_sm {
  font-size: 2.4rem;
}

.FontIcon_size_md {
  font-size: 3rem;
}

.FontIcon_size_lg {
  font-size: 4rem;
}

.FontIcon_size_xl {
  font-size: 5rem;
}