.Flex_root {
  display: flex;
}

.Flex_inline_flex {
  display: inline-flex;
}

.Flex_fill_items > * {
  flex: 1 1 auto;
}

.Flex_full_width {
  width: 100%;
}

.Flex_full_height {
  height: 100%;
}

.Flex_grow {
  flex-grow: 1;
}

.Flex_shrink {
  flex-shrink: 1;
}

.Flex_vertical {
  flex-direction: column;
}

.Flex_wrap {
  flex-wrap: wrap;
}

.Flex_gap_xxs {
  gap: 0.25rem;
}

.Flex_gap_xs {
  gap: 0.5rem;
}

.Flex_gap_sm {
  gap: 1rem;
}

.Flex_gap_md {
  gap: 1.5rem;
}

.Flex_gap_lg {
  gap: 2rem;
}

.Flex_gap_xl {
  gap: 3rem;
}

.Flex_gap_xxl {
  gap: 4rem;
}

.Flex_padding_xxs {
  padding: 0.25rem;
}

.Flex_padding_xs {
  padding: 0.5rem;
}

.Flex_padding_sm {
  padding: 1rem;
}

.Flex_padding_md {
  padding: 1.5rem;
}

.Flex_padding_lg {
  padding: 2.4rem;
}

.Flex_padding_xl {
  padding: 3.2rem;
}

.Flex_padding_xxl {
  padding: 4rem;
}

@media (max-width: 0px) {
  .Flex_horizontal_break_xs {
    flex-direction: column;
  }
}
@media (max-width: 600px) {
  .Flex_horizontal_break_sm {
    flex-direction: column;
  }
}
@media (max-width: 900px) {
  .Flex_horizontal_break_md {
    flex-direction: column;
  }
}
@media (max-width: 1200px) {
  .Flex_horizontal_break_lg {
    flex-direction: column;
  }
}
@media (max-width: 1536px) {
  .Flex_horizontal_break_xl {
    flex-direction: column;
  }
}