.WaitSplash_backdrop {
  z-index: 1201;
  color: #fff;
  background-color: var(--color_bg);
}

.WaitSplash_container {
  margin: auto;
  text-align: center;
}

.WaitSplash_icon {
  position: relative;
  clear: both;
  z-index: 1000;
  width: 4em;
  height: 4em;
}

.WaitSplash_message {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
}