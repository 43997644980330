.FormFieldTabSelect_root {
  height: 100%;
}

.FormFieldTabSelect_content_container {
  display: inline-flex;
  height: 100%;
}

.FormFieldTabSelect_options_container {
  display: inline-flex;
  overflow: auto;
}
.FormFieldTabSelect_options_container * {
  flex-shrink: 0;
}

.FormFieldTabSelect_overflow_container {
  position: relative;
}
.FormFieldTabSelect_overflow_container.FormFieldTabSelect_overflow_container_open .FormFieldTabSelect_overflow_options_container {
  pointer-events: all;
  opacity: 1;
  z-index: 2;
  transform: scale(1);
}

.FormFieldTabSelect_overflow_options_container {
  position: absolute;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  opacity: 0;
  background-color: var(--color_bg);
  transform-origin: center;
  transform: scale(0.5);
  transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: -1;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.FormFieldTabSelect_btn {
  color: var(--color_text_primary);
  padding: 0 2rem;
  border-color: transparent;
  border-bottom-width: 4px;
  border-style: solid;
  background-color: transparent;
  border-radius: 0;
  outline: 0;
  user-select: none;
  text-decoration: none;
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Medium */
  font-weight: 500;
}
.FormFieldTabSelect_btn.FormFieldTabSelect_selected {
  color: var(--color_primary);
  border-bottom-color: var(--color_primary);
}