.ModifierCommandPaletteChip_root {
  /* Caption/Semibold */
  font-size: 1.2rem; /* 12px */
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
  color: var(--color_bg_secondary_contrast_text);
  background-color: var(--color_bg_secondary);
  border: 1px solid var(--color_bg_secondary_contrast_text);
  padding: 0.3rem 0.5rem;
  border-radius: 4rem;
  text-transform: uppercase;
}
.ModifierCommandPaletteChip_root.ModifierCommandPaletteChip_minus {
  color: var(--color_warn);
  border-color: var(--color_warn);
}
.ModifierCommandPaletteChip_root.ModifierCommandPaletteChip_plus {
  color: var(--color_success);
  border-color: var(--color_success);
}