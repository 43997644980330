.CheckSplitBySeat_container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px;
}

.CheckSplitBySeat_table {
  position: relative;
  border-radius: 50%;
  background-color: var(--color_bg_tertiary);
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  width: 10vw;
  height: 10vw;
  max-width: 30rem;
  max-height: 30rem;
  min-width: 10rem;
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CheckSplitBySeat_seat-button {
  position: absolute;
  padding: 0;
  width: 60px;
  height: 60px;
  background-color: var(--color_bg_tertiary);
}
.CheckSplitBySeat_seat-button.CheckSplitBySeat_selected {
  background-color: var(--color_grey_600);
}