.ProgressStepper_label {
  color: var(--color_grey_500);
}
.ProgressStepper_label.ProgressStepper_active {
  color: var(--color_primary);
}
.ProgressStepper_label.ProgressStepper_completed {
  color: var(--color_primary);
}

.ProgressStepper_connector_line {
  flex: 1 1 auto;
  border-color: var(--color_grey_500);
}
.ProgressStepper_connector_line.ProgressStepper_active {
  border-color: var(--color_primary);
}
.ProgressStepper_connector_line.ProgressStepper_completed {
  border-color: var(--color_primary);
}