// css vars built from below
$-tonal-offset: 10%;

$-hex-standard-white: #FFFFFF;

$-hex-grey-100: #D3D3D3;
$-hex-grey-200: #A7A7A8;
$-hex-grey-300: #7A7A7C;
$-hex-grey-400: #646466;
$-hex-grey-500: #4D4E50;
$-hex-grey-600: #424345;
$-hex-grey-700: #363436;
$-hex-grey-800: #303134;
$-hex-grey-900: #202124;

$-hex-primary: #E79D3C;
$-hex-secondary: $-hex-standard-white;

$-hex-info: #2095FA;
$-hex-success: #22BB33;
$-hex-error: #EA271B;
$-hex-warn: #E79D3C;

$-hex-bg-1: #0C141B;
$-hex-bg-2: #303134;
$-hex-bg-3: $-hex-grey-900;

$colors-dark-map: (
  // primary
  color_primary: $-hex-primary,
  color_primary_lite: lighten($-hex-primary, $-tonal-offset),
  color_primary_dark: darken($-hex-primary, $-tonal-offset),
  color_primary_contrast_text: $-hex-grey-800,
  // secondary
  color_secondary: $-hex-secondary,
  color_secondary_lite: lighten($-hex-secondary, $-tonal-offset),
  color_secondary_dark: darken($-hex-secondary, $-tonal-offset),
  color_secondary_contrast_text: $-hex-grey-800,
  // info
  color_info: $-hex-info,
  color_info_lite: lighten($-hex-info, $-tonal-offset),
  color_info_dark: darken($-hex-info, $-tonal-offset),
  color_info_contrast_text: $-hex-standard-white,
  // success
  color_success: $-hex-success,
  color_success_lite: lighten($-hex-success, $-tonal-offset),
  color_success_dark: darken($-hex-success, $-tonal-offset),
  color_success_contrast_text: $-hex-standard-white,
  // error
  color_error: $-hex-error,
  color_error_lite: lighten($-hex-error, $-tonal-offset),
  color_error_dark: darken($-hex-error, $-tonal-offset),
  color_error_contrast_text: $-hex-standard-white,
  // warning
  color_warn: $-hex-warn,
  color_warn_lite: lighten($-hex-warn, $-tonal-offset),
  color_warn_dark: darken($-hex-warn, $-tonal-offset),
  color_warn_contrast_text: $-hex-grey-800,

  color_bg: $-hex-bg-1,
  color_bg_lite: lighten($-hex-bg-1, $-tonal-offset),
  color_bg_dark: darken($-hex-bg-1, $-tonal-offset),
  color_bg_contrast_text: $-hex-standard-white,

  color_bg_secondary: $-hex-bg-2,
  color_bg_secondary_lite: lighten($-hex-bg-2, $-tonal-offset),
  color_bg_secondary_dark: darken($-hex-bg-2, $-tonal-offset),
  color_bg_secondary_contrast_text: $-hex-standard-white,

  color_bg_tertiary: $-hex-bg-3,
  color_bg_tertiary_lite: lighten($-hex-bg-3, $-tonal-offset),
  color_bg_tertiary_dark: darken($-hex-bg-3, $-tonal-offset),
  color_bg_tertiary_contrast_text: $-hex-standard-white,

  // TODO: remove transparent colors
  color_transparent_lite_25: rgba($-hex-standard-white, 0.25),
  color_transparent_lite_33: rgba($-hex-standard-white, 0.33),
  color_transparent_lite_50: rgba($-hex-standard-white, 0.50),
  color_transparent_dark_50: rgba($-hex-bg-1, 0.50),

  // text (should contrast well on all color background variations)
  // TODO: update Design System Figma files to include text color variations
  color_text_primary: $-hex-standard-white,
  color_text_secondary: #9E9E9E,
  color_text_tertiary: #757575,
  color_text_disabled: $-hex-grey-400,

  // greys
  color_grey_100: $-hex-grey-100,
  color_grey_200: $-hex-grey-200,
  color_grey_300: $-hex-grey-300,
  color_grey_400: $-hex-grey-400,
  color_grey_500: $-hex-grey-500,
  color_grey_600: $-hex-grey-600,
  color_grey_700: $-hex-grey-700,
  color_grey_800: $-hex-grey-800,
  color_grey_900: $-hex-grey-900,

  // divider
  color_divider: #BDBDBD,
  color_divider_lite: lighten(#BDBDBD, $-tonal-offset),
  color_divider_dark: darken(#BDBDBD, $-tonal-offset),

  // posColors
  // these are copied from pos-components, in the future when we have a better method for sharing colors between pos and bridge, they should probably move back to pos-components
  color_menu_fave: #FFDB15,
);