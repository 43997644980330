.ChecksList_filterBar {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.ChecksList_employeeIdFilterSelect {
  width: 400px;
}

.ChecksList_editTipsBtn {
  margin-left: auto;
  color: var(--color_primary);
}

.ChecksList_doneSearchBtn {
  margin-left: auto;
}

.ChecksList_resetFilterBtn {
  color: var(--color_primary);
}

.ChecksList_searchBar {
  max-width: 590px;
}

.ChecksList_corner_triangle {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 3rem 3rem;
  border-color: transparent transparent transparent var(--color_warn);
  left: 0;
  top: 0;
  position: absolute;
}