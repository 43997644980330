.Copy_strike {
  text-decoration: line-through;
}

.Copy_ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.Copy_percent {
  white-space: nowrap;
}

.Copy_disabled {
  opacity: 50%;
}

.Copy_container {
  position: relative;
}

.Copy_full_width {
  width: 100%;
}

.Copy_icon {
  top: -1.75rem;
  right: -1.25rem;
}

.Copy_required::after {
  content: " *";
  display: inline;
}

.Copy_positive::before {
  content: "+";
  display: inline;
  font-weight: bolder;
}

.Copy_negative::before {
  content: "-";
  font-weight: bolder;
  display: inline;
}

.Copy_single_line {
  white-space: nowrap;
}

.Copy_user_select {
  user-select: none;
}

.Copy_variant_h1 {
  /* H1/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 15.2rem; /* 152px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 152px */
}

.Copy_variant_h2 {
  /* H2/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 10rem; /* 100px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 100px */
}

.Copy_variant_h3 {
  /* H3/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 6.8rem; /* 68px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 68px */
}

.Copy_variant_h4 {
  /* H4/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 4.8rem; /* 48px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 48px */
}

.Copy_variant_h5 {
  /* H5/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 3.6rem; /* 36px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 36px */
}

.Copy_variant_h6 {
  /* H6/Bold */
  font-family: "Century-Gothic-Pro";
  font-size: 2.8rem; /* 28px */
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 28px */
}

.Copy_variant_body {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.Copy_variant_body.Copy_weight_regular {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Regular */
  font-weight: 400;
}
.Copy_variant_body.Copy_weight_medium {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/Medium */
  font-weight: 500;
}
.Copy_variant_body.Copy_weight_semi_bold {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
}
.Copy_variant_body.Copy_weight_bold {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* BodyLG/Bold */
  font-weight: 800;
}
.Copy_variant_body.Copy_size_sm {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/Regular */
  font-weight: 400;
}
.Copy_variant_body.Copy_size_lg {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/Regular */
  font-weight: 400;
}

.Copy_variant_button {
  /* Body/Regular */
  font-style: normal;
  /* Body/Regular */
  font-size: 2rem; /* 20px */
  line-height: 140%; /* 28px */
  /* Body/SemiBold */
  font-weight: 700;
}
.Copy_variant_button.Copy_size_sm {
  /* Body/Regular */
  font-style: normal;
  /* BodySM */
  font-size: 1.6rem; /* 16px */
  line-height: 140%; /* 22.4px */
  /* Body/SemiBold */
  font-weight: 700;
}
.Copy_variant_button.Copy_size_lg {
  /* Body/Regular */
  font-style: normal;
  /* BodyLG */
  font-size: 2.4rem; /* 24px */
  line-height: 140%; /* 33.6px */
  /* Body/SemiBold */
  font-weight: 700;
}

.Copy_variant_caption {
  /* Caption/Semibold */
  font-size: 1.2rem; /* 12px */
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 16.8px */
}

.Copy_color_primary {
  color: var(--color_primary);
}

.Copy_color_secondary {
  color: var(--color_secondary);
}

.Copy_color_text_primary {
  color: var(--color_text_primary);
}

.Copy_color_text_tertiary {
  color: var(--color_text_tertiary);
}

.Copy_color_text_secondary {
  color: var(--color_text_secondary);
}

.Copy_color_text_disabled {
  color: var(--color_text_disabled);
}

.Copy_color_grey_200 {
  color: var(--color_grey_200);
}

.Copy_color_grey_800 {
  color: var(--color_grey_800);
}

.Copy_color_error {
  color: var(--color_error);
}

.Copy_color_warning {
  color: var(--color_warn);
}

.Copy_color_initial {
  color: initial;
}

.Copy_color_inherit {
  color: inherit;
}

.Copy_align_center {
  text-align: center;
}

.Copy_align_inherit {
  text-align: inherit;
}

.Copy_align_justify {
  text-align: justify;
}

.Copy_align_left {
  text-align: left;
}

.Copy_align_right {
  text-align: right;
}

.Copy_italic {
  font-style: italic;
}

.Copy_uppercase {
  text-transform: uppercase;
}

.Copy_hidden {
  display: none;
}

.Copy_transparent {
  opacity: 0;
}

.Copy_padding_xxs {
  padding: 0.25rem;
}

.Copy_padding_xs {
  padding: 0.5rem;
}

.Copy_padding_sm {
  padding: 1rem;
}

.Copy_padding_md {
  padding: 1.5rem;
}

.Copy_padding_lg {
  padding: 2.4rem;
}

.Copy_padding_xl {
  padding: 3.2rem;
}

.Copy_padding_xxl {
  padding: 4rem;
}