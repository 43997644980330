.StatusIcon_icon_skin {
  transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.StatusIcon_has_background.StatusIcon_color_stationary .StatusIcon_icon_skin {
  background: var(--color_grey_500);
}
.StatusIcon_has_background.StatusIcon_color_success .StatusIcon_icon_skin {
  background: var(--color_success);
}
.StatusIcon_has_background.StatusIcon_color_info .StatusIcon_icon_skin {
  background: var(--color_info);
}
.StatusIcon_has_background.StatusIcon_color_error .StatusIcon_icon_skin {
  background: var(--color_error);
}
.StatusIcon_has_background.StatusIcon_color_warning .StatusIcon_icon_skin {
  background: var(--color_warn);
}

.StatusIcon_has_border.StatusIcon_color_stationary .StatusIcon_icon_skin {
  border: 2px solid var(--color_grey_500);
}
.StatusIcon_has_border.StatusIcon_color_success .StatusIcon_icon_skin {
  border: 2px solid var(--color_success);
}
.StatusIcon_has_border.StatusIcon_color_info .StatusIcon_icon_skin {
  border: 2px solid var(--color_info);
}
.StatusIcon_has_border.StatusIcon_color_error .StatusIcon_icon_skin {
  border: 2px solid var(--color_error);
}
.StatusIcon_has_border.StatusIcon_color_warning .StatusIcon_icon_skin {
  border: 2px solid var(--color_warn);
}

.StatusIcon_size_xs .StatusIcon_icon_skin {
  width: 0.5rem;
  height: 0.5rem;
}

.StatusIcon_size_sm .StatusIcon_icon_skin {
  width: 1rem;
  height: 1rem;
}

.StatusIcon_size_md .StatusIcon_icon_skin {
  width: 1.3rem;
  height: 1.3rem;
}

.StatusIcon_size_lg .StatusIcon_icon_skin {
  width: 3rem;
  height: 3rem;
}

.StatusIcon_size_xl .StatusIcon_icon_skin {
  width: 4rem;
  height: 4rem;
}