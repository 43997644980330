.CheckRouter_menu-invisible {
  visibility: hidden;
}

.CheckRouter_full-page-modal {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: var(--color_bg);
}
.CheckRouter_full-page-modal.CheckRouter_full-page-modal-show {
  display: block;
}